import React from 'react'
import { Link } from 'gatsby'

// components
import Layout from '../components/Layout'
// import Pagina from '../components/Pagina'

const NotFoundPage = ({ data }) => {
  return (
    <Layout>
      {/* <Pagina pagina={data.pagina}/> */}
      {/* <Img fluid={data.image404.childImageSharp.fluid} className='d-none d-lg-block' alt='Imagem erro 404' /> */}
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='mt-5'>Ops! Nenhuma página encontrada.</h1>
            <div className='col-12 col-lg-4 p-0 mt-4 mb-5'>
              <Link to='/' className="d-flex align-items-center justify-content-center mr-3 px-4 w-100 btn btn-blue text-uppercase align-middle font-weight-bold">
                <span className="text-uppercase align-middle font-weight-bold">ir para a home do site</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
